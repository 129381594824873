import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { AdsGETApiAuth, AdsPUTApi } from "../../../Constant/api";
import { slugs } from "../../../Constant/slugs";


export default function AllResults() {
  const [Projects1, setProjects1] = React.useState([])
  const [CustomersList, setCustomersList] = React.useState([])
  const [StudentsList, setStudentsList] = React.useState([])
  const [StudentsList1, setStudentsList1] = React.useState([])
  const [ExamType, setExamType] = React.useState([])
  const [ExamList, setExamList] = React.useState([])
  const [ExamList1, setExamList1] = React.useState([])
  const [Reload, setReload] = React.useState(false)
  const [AllTotal, setAllTotal] = React.useState(0)
  const [serachStudent, setSearchStudent] = React.useState("")
  const [serachStudentPh, setSearchStudentPh] = React.useState("")
  const [serachExam, setSearchExam] = React.useState("")
  const [serachExamID, setSearchExamID] = React.useState("")
  const [SubscriberTotal, setSubscriberTotal] = React.useState(0)
  const [StudentTotal, setStudentTotal] = React.useState(0)
  const [AdminTotal, setAdminTotal] = React.useState(0)
  const [ExamTotal, setExamTotal] = React.useState(0)
  const [choose, setChoose] = React.useState(0)
  const date = new Date()

  const monthToday = date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
  const dateToday = date.getDate() <= 9 ? `0${date.getDate()}` : date.getDate()
  const dateYe = new Date(date - 24 * 60 * 60 * 1000)

  const monthY = dateYe.getMonth() < 9 ? `0${dateYe.getMonth() + 1}` : dateYe.getMonth() + 1
  const dateY = dateYe.getDate() <= 9 ? `0${dateYe.getDate()}` : dateYe.getDate()

  const GetAllProjects = (params, setData, slug, setTotal, setList1) => {
    setReload(true)
    setProjects1([])
    AdsGETApiAuth(params, slug)
      .then((res) => {
        console.log(res.data)
        setData(res.data)
        setTotal(res.headers["x-wp-total"])
        // if (slug == slugs.db_slug_paid_results)
        setList1(res.data)
        console.log("done")
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        setReload(false)
      })
  }

  const GetAllTotal = () => {
    AdsGETApiAuth({ per_page: 1, _fields: "id" }, slugs.db_slug_paid_results)
      .then((res) => {
        setAllTotal(res.headers["x-wp-total"])
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        setReload(false)
      })
  }

  useEffect(() => {
    GetAllTotal()
    GetAllProjects({
      per_page: 2000,
      _fields: "id,title,slug,meta.student_id_results,meta.exam_id_results,meta.exam_total_results,meta.exam_date_results,meta.exam_name_results,meta.exam_type_results,meta.exam_sub_type_results"
    }, setProjects1, slugs.db_slug_paid_results, setAllTotal, setCustomersList)
    GetAllProjects({
      per_page: 500,
      _fields: "id,title,slug,meta.available_unavailable,meta.exam_type"
    }, setExamList, slugs.db_slug_paid_exam, setExamTotal, setExamList1)
    GetAllProjects({
      per_page: 4900,
      _fields: "id,title,slug,meta.user_phone_number,meta.batch_name,meta.user_id"
    }, setStudentsList, slugs.db_slug_user_data, setStudentTotal, setStudentsList1)
    // GetTotal("student", setStudentTotal, slugs.db_slug_user_data)
    // GetTotal("", setExamTotal, slugs.db_slug_paid_exam)
    // GetTotal("", setAllTotal, slugs.db_slug_paid_results)
  }, [])

  const columnsExam = [
    {
      id: 1,
      name: "id",
      selector: (row) => row.id,
      sortable: true,
      reorder: true,
      width: "100px"
    },
    {
      id: 31,
      name: "Title",
      selector: (row) => row.title.rendered,
      sortable: true,
      reorder: true,
    },
    {
      id: 33,
      name: "Availability",
      selector: (row) => row.meta.available_unavailable,
      sortable: true,
      reorder: true,
      width: "150px"
    },
    {
      id: 34,
      name: "Type",
      selector: (row) => row.meta.exam_type,
      sortable: true,
      reorder: true
    },
    // {
    //   id: 34,
    //   name: "Total Exams",
    //   selector: (row) => CustomersList.filter(a => a.meta.exam_id_results == row.id).length,
    //   sortable: true,
    //   reorder: true
    // },
    {
      id: 3,
      name: "Choose",
      right: true,
      cell: (row) => <div style={{ cursor: "pointer" }}
        onClick={() => {
          // setProjects1(CustomersList.filter(a => a.meta.exam_id_results == row.id))
          GetAllProjects({
            per_page: 2000,
            _fields: "id,title,slug,meta.student_id_results,meta.exam_id_results,meta.exam_total_results,meta.exam_date_results,meta.exam_name_results,meta.exam_type_results,meta.exam_sub_type_results",
            "filter[meta_query][1][key]": "exam_id_results",
            "filter[meta_query][1][value][0]": row.id,
            "filter[meta_query][1][compare]": "=",
          }, setProjects1, slugs.db_slug_paid_results, setAllTotal)
          setChoose(0)
        }}
      >
        Choose
      </div>,

    }
  ];
  const columnsStudent = [
    {
      id: 1,
      name: "id",
      selector: (row) => row.meta.user_id,
      sortable: true,
      reorder: true,
    },
    {
      id: 31,
      name: "Title",
      selector: (row) => row.title.rendered,
      sortable: true,
      reorder: true
    },
    {
      id: 312,
      name: "Phone",
      selector: (row) => row.meta.user_phone_number,
      sortable: true,
      reorder: true
    },
    {
      id: 32,
      name: "Batch",
      selector: (row) => row.meta.batch_name,
      sortable: true,
      reorder: true
    },
    // {
    //   id: 3421,
    //   name: "Total Exams",
    //   selector: (row) => CustomersList.filter(a => a.meta.student_id_results == row.meta.user_id).length,
    //   sortable: true,
    //   reorder: true
    // },
    {
      id: 3123,
      name: "Choose",
      right: true,
      cell: (row) => <div style={{ cursor: "pointer" }}
        onClick={() => {
          GetAllProjects({
            per_page: 2000,
            _fields: "id,title,slug,meta.student_id_results,meta.exam_id_results,meta.exam_total_results,meta.exam_date_results,meta.exam_name_results,meta.exam_type_results,meta.exam_sub_type_results",
            "filter[meta_query][1][key]": "student_id_results",
            "filter[meta_query][1][value][0]": row.id,
            "filter[meta_query][1][compare]": "=",
          }, setProjects1, slugs.db_slug_paid_results, setAllTotal)
          // setProjects1(CustomersList.filter(a => a.meta.student_id_results == row.meta.user_id))
          setChoose(0)
        }}
      >
        Choose
      </div>,

    }
  ];
  const columns = [
    {
      id: 1,
      name: "id",
      selector: (row) => row.id,
      sortable: true,
      reorder: true,
    },
    {
      id: 31,
      name: "Exam",
      selector: (row) => row.meta.exam_name_results,
      sortable: true,
      reorder: true
    },
    {
      id: 313,
      name: "Student Name",
      selector: (row) =>
        StudentsList.filter(a => a.meta.user_id == row.meta.student_id_results).length !== 0 ?
          StudentsList.filter(a => a.meta.user_id == row.meta.student_id_results)[0].title.rendered : ""
      ,
      sortable: true,
      reorder: true
    },
    {
      id: 312,
      name: "Exam id",
      selector: (row) => row.meta.exam_id_results,
      sortable: true,
      reorder: true
    },
    {
      id: 314,
      name: "Total Score",
      selector: (row) => row.meta.exam_total_results,
      sortable: true,
      reorder: true
    },
    {
      id: 315,
      name: "Date",
      selector: (row) => row.meta.exam_date_results,
      sortable: true,
      reorder: true
    },
    {
      id: 32,
      name: "Delete",
      right: true,
      cell: (row) => <div style={{ cursor: "pointer" }}
        onClick={() => {
          AdsPUTApi({
            status: "draft"
          }, slugs.db_slug_paid_results, row.id)
            .then((res) => {
              setSearchStudent("")
              setSearchExam("")
              setSearchExamID("")
              GetAllProjects({
                per_page: 2000,
                _fields: "id,title,slug,meta.student_id_results,meta.exam_id_results,meta.exam_total_results,meta.exam_date_results,meta.exam_name_results"
              }, setProjects1, slugs.db_slug_paid_results, setAllTotal)

            })
            .catch((err) => {
              console.log(err)
            })
        }}
      >Delete</div>,

    },
    {
      id: 32,
      name: "View",
      right: true,
      cell: (row) => <Link to={`${slugs.result_admin_view}/${row.slug}`}>View</Link>,

    }
  ];
  return (
    <div>
      <div className="row" style={{ padding: "30px 30px" }}>
        <div className="col-md-8 button">
          <h2>All Results</h2>
        </div>
        <div className="col-md-4" style={{ textAlign: 'right' }}>
          <a href="/">Dashboard</a> / All Daily Exams
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div style={{ display: "inline", cursor: "pointer" }}>
            <div style={{ display: "inline-block", margin: "0px 5px" }}
              onClick={() => {
                setChoose(0)
                setProjects1(CustomersList)
              }}
            >
              All ({AllTotal}) |
            </div>
            <div style={{ display: "inline-block", margin: "0px 5px" }}
              onClick={() => {
                setChoose(1)
              }}
            >
              Exams ({ExamTotal}) |
            </div>
            <div style={{ display: "inline-block", margin: "0px 5px" }}
              onClick={() => {
                setChoose(2)
              }}
            >
              Students ({StudentTotal}) |
            </div>
            <div style={{ display: "inline-block", margin: "0px 5px" }}
              onClick={() => {
                setProjects1(CustomersList)
              }}
            >
              Clear
            </div>
          </div>
        </div>
        <div className="col-md-6 row">
          <div className="col-2"
            onClick={() => {

              GetAllProjects({
                per_page: 2000,
                _fields: "id,title,slug,meta.student_id_results,meta.exam_id_results,meta.exam_total_results,meta.exam_date_results,meta.exam_name_results,meta.exam_type_results,meta.exam_sub_type_results",
                "filter[meta_query][1][key]": "exam_date_results",
                "filter[meta_query][1][value][0]": `${date.getFullYear()}-${monthToday}-${dateToday}`,
                "filter[meta_query][1][compare]": "=",
              }, setProjects1, slugs.db_slug_paid_results, setAllTotal)
            }}
          >
            Today
          </div>
          <div className="col-2"
            onClick={() => {
              GetAllProjects({
                per_page: 2000,
                _fields: "id,title,slug,meta.student_id_results,meta.exam_id_results,meta.exam_total_results,meta.exam_date_results,meta.exam_name_results,meta.exam_type_results,meta.exam_sub_type_results",
                "filter[meta_query][1][key]": "exam_date_results",
                "filter[meta_query][1][value][0]": `${dateYe.getFullYear()}-${monthY}-${dateY}`,
                "filter[meta_query][1][compare]": "=",
              }, setProjects1, slugs.db_slug_paid_results, setAllTotal)
            }}
          >
            Yesterday
          </div>
          {choose == 0 &&
            <>
              <input className="col-3" style={{ margin: "1px 2px", padding: "3px 2px" }}
                value={serachExam} placeholder="Exam Name"
                onChange={(event) => {
                  setSearchExam(event.target.value)
                  setProjects1(CustomersList.filter(a => a.meta.exam_name_results.includes(event.target.value)))
                }}
              />
              <input className="col-2" style={{ margin: "1px 2px", padding: "3px 2px" }}
                value={serachExamID} placeholder="Exam ID"
                onChange={(event) => {
                  setSearchExamID(event.target.value)
                  setProjects1(CustomersList.filter(a => a.meta.exam_id_results.includes(event.target.value)))
                }}
              />
            </>
          }
          {choose == 2 &&
            <>
              <input className="col-2" style={{ margin: "1px 2px", padding: "3px 2px" }}
                value={serachExamID} placeholder="Student ID"
                onChange={(event) => {
                  setSearchExamID(event.target.value)
                  setStudentsList(StudentsList1.filter(a => a.meta.user_id.includes(event.target.value)))
                }}
              />
              <input className="col-2" style={{ margin: "1px 2px", padding: "3px 2px" }}
                value={serachStudent} placeholder="Student Name"
                onChange={(event) => {
                  setSearchStudent(event.target.value)
                  setStudentsList(StudentsList1.filter(a => a.meta.user_name.includes(event.target.value)))
                  // setProjects1(CustomersList.filter(a => a.meta.student_id_results.includes(event.target.value)))
                }}
              />
                <input className="col-2" style={{ margin: "1px 2px", padding: "3px 2px" }}
                value={serachStudentPh} placeholder="Phone Number"
                onChange={(event) => {
                  setSearchStudentPh(event.target.value)
                  setStudentsList(StudentsList1.filter(a => a.meta.user_phone_number.includes(event.target.value)))
                  // setProjects1(CustomersList.filter(a => a.meta.student_id_results.includes(event.target.value)))
                }}
              />
            </>
          }
        </div>
      </div>


      <div style={{ backgroundColor: "#fff", padding: "10px", width: "97%", margin: "10px" }}>
        {choose == 0 &&
          <DataTable
            columns={columns}
            data={Projects1}
            defaultSortFieldId={1}
            defaultSortAsc={false}
            striped
            // sortIcon={<SortIcon />}
            pagination
            selectableRows
          />
        }
        {choose == 1 &&
          <>
            <DataTable
              columns={columnsExam}
              data={ExamList}
              defaultSortFieldId={1}
              defaultSortAsc={false}
              striped
              // sortIcon={<SortIcon />}
              pagination
              selectableRows
            />
          </>
        }
        {choose == 2 &&
          <DataTable
            columns={columnsStudent}
            data={StudentsList}
            defaultSortFieldId={1}
            defaultSortAsc={false}
            striped
            // sortIcon={<SortIcon />}
            pagination
            selectableRows
          />
        }
      </div>
      {Reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
    </div>
  )
}