import React from "react";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AdsPOSTApi, AdsPUTApi } from "../../../../Constant/api";
import { slugs } from "../../../../Constant/slugs";
import { AlertComponent } from "../../../AlertComponent";
import { CustomButton } from "../../../CustomButtom";
import FileUpload from "../../../FileUpload";
import ImageUpload from "../../../ImageUpload";
import { CustomButtonUnSelect } from "../../../CustomButtomUnselect";
import ImageUploadAuto from "./ImageUpload1";

export default function FormComponent(props) {
  const { FetchData } = props;
  const history = useHistory()
  const [ID, setID] = React.useState(FetchData.id)

  const [Title, setTitle] = React.useState(FetchData.title.rendered)
  const [Korean, setKorean] = React.useState(FetchData.meta.korean_paragraph)
  const [Nepali, setNepali] = React.useState(FetchData.meta.nepali_paragraph)
  const [FeaturedImage, setFeaturedImage] = React.useState(FetchData.meta.featured_image)
  const [Free, setFree] = React.useState(FetchData.meta.free)

  const [reload, setReload] = React.useState(false)

  const [AlertShow, setAlertShow] = React.useState(false)
  const [Alertcomp, setAlertcomp] = React.useState("")
  const [QuestionsBlock, setQuestionsBlock] = React.useState(true);
  const [QuestionsData, setQuestionsData] = React.useState(
    JSON.parse(FetchData.meta.details)
  );
  const [QuestionsEditMode, setQuestionsEditMode] = React.useState(false);
  const [QuestionsEditData, setQuestionsEditData] = React.useState({});
  const [QuestionsEditKey, setQuestionsEditKey] = React.useState();

  const EditInfo = () => {
    setReload(true)
    const data = {
      "title": Title,
      "status": "publish",
      "meta": {
        "nepali_paragraph": Nepali,
        "korean_paragraph": Korean,
        "featured_image": FeaturedImage,
        "free": Free,
        "details": JSON.stringify(QuestionsData)
      }
    }

    if (ID === "")
      AdsPOSTApi(data, slugs.db_slug_note)
        .then((res) => {
          setReload(false)
          setID(res.data.id)
          setAlertShow(true)
          setAlertcomp("Batch Added Successfully")
          history.push(`${slugs.note_details}${res.data.slug}`)
        }).catch(err => {
          setReload(false)
          console.log(err)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
    else
      AdsPUTApi(data, slugs.db_slug_note, ID)
        .then((res) => {
          setReload(false)
          setAlertShow(true)
          setAlertcomp(`${Title} edited Successfully`)
        }).catch(err => {
          console.log(err)
          console.warn(err.response.data)
          console.log("Response Status: hello11", err.response.status);
          console.log("Response Headers:", err.response.headers);
          setReload(false)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
  }
  const onChangeFunction = (key, value, index) => {
    var changeValue = {
      ...Object.values(QuestionsData)[index],
      [key]: value
    }
    var AllChange = {
      ...QuestionsData,
      [Object.keys(QuestionsData)[index]]: changeValue
    }
    setQuestionsData(AllChange)

  }
  return (
    <div>
      <div className="row">
        <div className="col-sm-2 col-1"></div>
        <div className="col-sm-8 col-10 form-view mb-3">
          <h4><center>Note Information</center></h4>
          <table>
            <tbody>
              <tr>
                <td className="col-3">Title</td>
                <td className="col-6">
                  <input className="input-common" style={{ width: "100%" }}
                    value={Title} placeholder="Title"
                    onChange={(event) => { setTitle(event.target.value) }}
                  />
                </td>
              </tr>
              {/* <tr>
                <td className="col-3">Korean </td>
                <td className="col-6">
                  <textarea rows={5} className="input-common" style={{ width: "100%" }}
                    value={Korean} placeholder="Korean"
                    onChange={(event) => { setKorean(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Nepali</td>
                <td className="col-6">
                  <textarea rows={5} className="input-common" style={{ width: "100%" }}
                    value={Nepali} placeholder="Name"
                    onChange={(event) => { setNepali(event.target.value) }}
                  />
                </td>
              </tr> */}
              <tr>
                <td className="col-3">Photo</td>
                <td className="col-6">
                  <ImageUpload
                    ImageUrl={FeaturedImage}
                    setImageUrl={setFeaturedImage}
                  />
                </td>
              </tr>
              <td className="col-3">Type</td>
              <td className="col-6">
                <div>
                  <CustomButtonUnSelect setData={setFree} Data={Free} DataValue={"Free"} />
                </div>
              </td>
            </tbody>
          </table>
          <div className="row center m-2">
            <div className=" col-3" style={{ padding: "0 10px" }}>
              <div className="sign-in-button-4"
                onClick={() => {
                  EditInfo()
                }}
              >
                Update Notes
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-2 col-1"></div>
        <div className="col-sm-8 col-10 form-view mb-3">
          <h4><center>Notes</center></h4>
          <div style={{ display: "inline", justifyContent: "flex-end" }}>
            <div style={{ cursor: "pointer", display: "inline-block", margin: "5px", color: QuestionsBlock ? "#000000" : "#444444", fontWeight: QuestionsBlock ? "bold" : "normal" }}
              onClick={() => {
                setQuestionsBlock(true)
              }}
            >
              Edit
            </div>
            <div style={{ cursor: "pointer", display: "inline-block", margin: "5px", color: !QuestionsBlock ? "#000000" : "#444444", fontWeight: !QuestionsBlock ? "bold" : "normal" }}
              onClick={() => {
                setQuestionsBlock(false)
              }}
            >
              View
            </div>
          </div>
          {QuestionsBlock ?
            <div>
              {Object.keys(QuestionsData).length + 1 > 5 &&
                <div className="row center m-2">
                  <div className=" col-3" style={{ padding: "0 10px" }}>
                    <div className="sign-in-button-4"
                      onClick={() => {
                        var newQues = {
                          "number": `${Object.keys(QuestionsData).length + 1}`,
                          "title": "",
                          "korean": "",
                          "nepali": "",
                          "image": "",
                        }
                        var allQues = {
                          ...QuestionsData,
                          [`item-${Object.keys(QuestionsData).length}`]: newQues
                        }
                        setQuestionsData(allQues)
                      }}
                    >
                      Add Note
                    </div>
                  </div>
                </div>
              }
              <div>
                {Object.values(QuestionsData).map((oneQuestionData, index) => (
                  <section key={index} style={{
                    pageBreakInside: "avoid", display: "block", cursor: "pointer", padding: "3px 0",
                  }}>
                    <div className="row"
                      style={{
                        border: "1px solid #999",
                        borderRadius: "5px",
                        margin: "5px",
                        padding: "10px",
                        backgroundColor: "#fff",
                      }}>
                      <div
                        style={{ padding: "5px 0 0 0" }}
                      >
                        <h3 style={{ fontSize: "18px", fontWeight: "bold", margin: 0, paddingBottom: 0 }}>
                          {oneQuestionData.number}.
                        </h3>
                        <table>
                          <tbody>
                            <tr>
                              <td className="col-3">Title</td>
                              <td className="col-9">
                                <input className="input-common" style={{ width: "100%" }}
                                  value={oneQuestionData.title} placeholder="Title"
                                  onChange={(event) => {
                                    onChangeFunction("title", event.target.value, index)
                                  }}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="col-3">Korean</td>
                              <td className="col-9">
                                <textarea rows={5} className="input-common" style={{ width: "100%" }}
                                  value={oneQuestionData.korean} placeholder="Korean"
                                  onChange={(event) => {
                                    onChangeFunction("korean", event.target.value, index)
                                  }}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="col-3">Nepai</td>
                              <td className="col-9">
                                <textarea rows={5} className="input-common" style={{ width: "100%" }}
                                  value={oneQuestionData.nepali} placeholder="Nepali"
                                  onChange={(event) => {
                                    onChangeFunction("nepali", event.target.value, index)
                                  }}
                                />
                              </td>
                            </tr>

                            <tr>
                              <td className="col-3">Korean</td>
                              <td className="col-9">
                                <ImageUploadAuto
                                  ImageUrl={oneQuestionData.image}
                                  onChange={onChangeFunction}
                                  type={"image"}
                                  index={index}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                    </div>
                  </section>
                ))}
              </div>
              <div className="row center m-2">
                <div className=" col-3" style={{ padding: "0 10px" }}>
                  <div className="sign-in-button-4"
                    onClick={() => {
                      var newQues = {
                        "number": `${Object.keys(QuestionsData).length + 1}`,
                        "title": "",
                        "korean": "",
                        "nepali": "",
                        "image": "",
                      }
                      var allQues = {
                        ...QuestionsData,
                        [`item-${Object.keys(QuestionsData).length}`]: newQues
                      }
                      setQuestionsData(allQues)
                      // setQuestionsEditData({
                      //   "question_number": `${Object.keys(QuestionsData).length + 1}`,
                      //   "title": "",
                      //   "korean": "",
                      //   "nepali": "text",
                      //   "image": "",
                      // })
                      // setQuestionsEditKey(`item-${Object.keys(QuestionsData).length}`)
                      // setQuestionsEditMode(true)
                    }}
                  >
                    Add Note
                  </div>
                </div>
              </div>
            </div>
            :
            <div>
              <div>
                {Object.values(QuestionsData).map((oneQuestionData, index) => (
                  <section key={index} style={{
                    pageBreakInside: "avoid", display: "block", cursor: "pointer", padding: "3px 0",
                  }}>
                    <div className="row"
                      style={{
                        border: "1px solid #999",
                        borderRadius: "5px",
                        margin: "5px",
                        padding: "10px",
                        backgroundColor: "#fff",
                      }}>
                      <div className="col-sm-4"
                        style={{ padding: "5px 0 0 0" }}
                      >
                        <h3 style={{ fontSize: "18px", fontWeight: "bold", margin: 0, paddingBottom: 0 }}>
                          {oneQuestionData.number}. {oneQuestionData.title}
                        </h3>
                        <img src={oneQuestionData.image}
                          style={{ width: "100%" }}
                        />
                      </div>
                      <div className="col-sm-8">
                        <div dangerouslySetInnerHTML={{ __html: oneQuestionData.korean.replaceAll('\n', '<br/>') }}></div>
                        <br />
                        <div dangerouslySetInnerHTML={{ __html: oneQuestionData.nepali.replaceAll('\n', '<br/>') }}></div>
                      </div>
                    </div>
                  </section>
                ))}
              </div>
            </div>
          }
          {Object.keys(QuestionsData).length + 1 > 5 &&
            <div style={{ display: "inline", justifyContent: "flex-end" }}>
              <div style={{ cursor: "pointer", display: "inline-block", margin: "5px", color: QuestionsBlock ? "#000000" : "#444444", fontWeight: QuestionsBlock ? "bold" : "normal" }}
                onClick={() => {
                  setQuestionsBlock(true)
                }}
              >
                Edit
              </div>
              <div style={{ cursor: "pointer", display: "inline-block", margin: "5px", color: !QuestionsBlock ? "#000000" : "#444444", fontWeight: !QuestionsBlock ? "bold" : "normal" }}
                onClick={() => {
                  setQuestionsBlock(false)
                }}
              >
                View
              </div>
            </div>
          }
        </div>
      </div>

      {
        reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
      {
        AlertShow &&
        <AlertComponent Alertcomp={Alertcomp} setAlertShow={setAlertShow} />
      }
    </div >
  )

}