import React from "react";
import FormComponent from "./Components/Form";
import Database from './../../../Constant/database.json'
import { slugs } from "../../../Constant/slugs";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import ExamData from './Components/examData.json'

export default function NewExam() {

  const typeArray = {
    "ubt": "UBT",
    "chapter-exam": "Chapter",
    "question-bank": "Question Bank",
    "weekly-test": "Weekly Test",
    "grammer-test": "Grammer Test"
  }
  const location = useLocation()
  var type = location.pathname.slice(slugs.new_exam.length + 1) == "" ? "ubt" : location.pathname.slice(slugs.all_exam.length + 1)
  const FetchData = {
    "id": "",
    "title": {
      "rendered": ""
    },
    "meta": {
      "exam_question_block_text": ExamData.meta.exam_question_block_text,
      "exam_time": "",
      "exam_details": "",
      "available_unavailable": "Unavailable",
      "featured_image": "",
      "exam_type": typeArray[type],
      // "exam_type": "UBT",
      "exam_sub_type":""
    }
  };
  return (
    <div>
      <div className="row" style={{ padding: "30px 30px" }}>
        <div className="col-md-8 button">
          <h2>New Exam</h2>
        </div>
        <div className="col-md-4" style={{ textAlign: 'right' }}>
          <a href="/">Dashboard</a> / <a href={slugs.all_exam}>All Exam</a>
        </div>
      </div>
      <FormComponent
        FetchData={FetchData}
        db_slug={slugs.db_slug_paid_exam}
        webSlug={slugs.exam_details}
      />
    </div>
  )
}